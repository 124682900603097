import React, { useEffect, useRef, useState } from "react";
import { Marker } from "@react-google-maps/api";

export function MarkerWithDelay({
  index,
  markerIcon,
  ...props
}: React.ComponentProps<typeof Marker> & {
  index: number;
  markerIcon: google.maps.Icon | undefined;
}) {
  const startRef = useRef(Date.now());
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVisible(true);
    }, index * 150 - (Date.now() - startRef.current));
    return () => {
      clearTimeout(timeoutId);
    };
  }, [index]);

  return (
    <Marker
      {...props}
      // label={String(i)}
      icon={markerIcon}
      animation={google.maps.Animation.DROP}
      options={{ visible }}
    />
  );
}
