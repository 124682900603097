import { ChunkItem } from "./blogs";

export function getChunkKey(chunk: ChunkItem) {
  if ("media" in chunk) {
    return chunk.media;
  } else if ("text" in chunk) {
    return chunk.text;
  } else if ("lat" in chunk) {
    return `${chunk.lat}${chunk.lng}`;
  } else {
    if (process.env.NODE_ENV !== "production") {
      throw new Error("not handling chunk type");
    }
    return JSON.stringify(chunk);
  }
}
