export const allFonts = [
  "American Typewriter",
  "Futura",
  "Arial Black",
  "Arial Narrow",
  "Arial",
  "Avenir Next",
  "Avenir",
  "Courier New",
  "Courier",
  "Frutiger",
  "Geneva",
  "Georgia",
  "Helvetica",
  "Impact",
  "Palatino",
  "Tahoma",
  "Times New Roman",
  "Times",
  "Trebuchet MS",
  "Verdana",
  "Al Bayan",
  "Al Nile",
  "Al Tarikh",
  "Comic Sans MS",
];
