import React, { useCallback, useEffect, useState } from "react";
import { FontSelector } from "./FontSelector";

import { BlogPage } from "./blog/BlogPage";
import { isReactSnap } from "./blog/isReactSnap";
import history from "history/browser";

const App = () => {
  const [font, _setFont] = useState<string | undefined>(
    () => localStorage.getItem("font") ?? "Trebuchet MS"
  );
  const setFont = useCallback((f: string | undefined) => {
    if (f) {
      localStorage.setItem("font", f);
    } else {
      localStorage.removeItem("font");
    }
    _setFont(f);
  }, []);

  const [location, setLocation] = useState(history.location);
  useEffect(() => history.listen(({ location }) => setLocation(location)), []);

  const blogMatch = location.pathname.match(/\/blog(\/([^/]+)?)?/);
  if (blogMatch) {
    const postId = blogMatch[2];
    if (location.hash && !postId) {
      history.replace(`/blog/${location.hash.replace(/^#/, "")}`);
    }
    return <BlogPage font={font} setFont={setFont} postId={postId} />;
  }
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: font,
      }}
    >
      <div style={{ position: "absolute", top: 10, right: 10 }}>
        <FontSelector font={font} onChange={setFont} />
      </div>
      <h1>Jeffrey Burt</h1>
      <p>Coming Soon...</p>
      {!isReactSnap && (
        <div
          style={{
            position: "absolute",
            right: 10,
            bottom: 10,
            fontSize: 12,
            gap: 15,
            display: "flex",
          }}
        >
          <a href="blog">blog</a>
          <a href="furniture">Furniture</a>
        </div>
      )}
    </div>
  );
};

export default App;
