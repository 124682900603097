import React, { useLayoutEffect } from "react";
import { isReactSnap } from "./isReactSnap";

declare global {
  interface Window {
    webpushr: {
      (str: "setup", options: { key: string }): unknown;
      (str: "attributes", options: Record<string, string>): unknown;
      q?: unknown[];
    };
  }
}

function webPushInit() {
  if (isReactSnap || process.env.NODE_ENV === "development") return;
  (function (w, d, s: "script", id) {
    if (typeof w.webpushr !== "undefined") return;
    w.webpushr =
      w.webpushr ||
      function () {
        (w.webpushr.q = w.webpushr.q || []).push(arguments);
      };
    var fjs = d.getElementsByTagName(s)[0];
    const js = d.createElement(s);
    js.id = id;
    js.async = true;
    js.src = "https://cdn.webpushr.com/app.min.js";
    fjs.parentNode?.appendChild(js);
  })(window, document, "script", "webpushr-jssdk");
  window.webpushr("setup", {
    key: "BJcpNmzMzJ97C-ifGJVdCHdHMwkRixeQoEDJy3um_cepztUokbirfcDGH8U0QrMC9MkKqt3vxeb_flXPm7WDsEk",
  });
  window.webpushr("attributes", {
    name: window.localStorage.getItem("name") ?? "",
  });
}

export function WebPushSwitch() {
  useLayoutEffect(webPushInit, []);

  return (
    <span
      id="webpushr-subscription-toggle-button"
      data-size="1.1"
      data-text-when-denied="You've blocked push notifications."
      data-tooltip-position="left"
      data-color="#2c7be5"
    ></span>
  );
}
