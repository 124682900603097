import { publishedBlogs } from "./blogs";

const ADJUST_VERTICAL_SPACE =
  publishedBlogs.find((b) => typeof b.adjustMapPadding === "number")
    ?.adjustMapPadding ?? 25; //   70; // started at 25
const HORIZONTAL_SPACE = 20;

export function getBoundsZoomLevel(
  bounds: google.maps.LatLngBounds,
  mapDim: { height: number; width: number }
) {
  var WORLD_DIM = { height: 256, width: 256 };
  var ZOOM_MAX = 10;

  function latRad(lat: number) {
    var sin = Math.sin((lat * Math.PI) / 180);
    var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx: number, worldPx: number, fraction: number) {
    // return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    return Math.log(mapPx / worldPx / fraction) / Math.LN2;
  }

  var ne = bounds.getNorthEast();
  var sw = bounds.getSouthWest();

  var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  var lngDiff = ne.lng() - sw.lng();
  var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  var latZoom = zoom(
    mapDim.height - ADJUST_VERTICAL_SPACE,
    WORLD_DIM.height,
    latFraction
  );
  var lngZoom = zoom(
    mapDim.width - HORIZONTAL_SPACE,
    WORLD_DIM.width,
    lngFraction
  );

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}
