import React, { useRef, useState, useEffect } from "react";
import { allFonts } from "./allFonts";

export const FontSelector = ({
  font,
  onChange,
}: {
  font: string | undefined;
  onChange: (font: string | undefined) => void;
}) => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const [fonts, setFonts] = useState<string[]>([]);
  useEffect(() => {
    const span = spanRef.current;
    if (span) {
      span.style.fontFamily = "Comic Sans MS";
      const baseWidth = span.offsetWidth;
      const baseHeight = span.offsetHeight;
      const availableFonts = allFonts.filter((font) => {
        span.style.fontFamily = `${
          font === "Comic Sans MS" ? "Times New Roman" : font
        }, Comic Sans MS`;
        return (
          baseWidth !== span.offsetWidth || baseHeight !== span.offsetHeight
        );
      });
      setFonts(availableFonts);
    }
  }, [onChange]);

  useEffect(() => {
    if (font && fonts.length && !fonts.includes(font)) {
      onChange(undefined);
    }
  }, [font, fonts, onChange]);

  return (
    <>
      {fonts.length > 1 && (
        <label style={{ fontSize: 12 }}>
          Pick font:{" "}
          <select
            onChange={(e) => onChange(e.target.value)}
            defaultValue={font ?? ""}
          >
            <option disabled value="">
              -- select an font --
            </option>
            {fonts.map((font) => (
              <option key={font}>{font}</option>
            ))}
          </select>
        </label>
      )}
      <span
        ref={spanRef}
        style={{
          position: "absolute",
          left: -9999,
          visibility: "hidden",
          fontSize: 50,
        }}
      >
        test
      </span>
    </>
  );
};
