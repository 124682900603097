import React from "react";
import { getPostHash, publishedBlogs } from "./blogs";
import history from "history/browser";

export function SinglePostNav({
  postIndex,
  style,
}: {
  postIndex: number;
  style: React.CSSProperties;
}) {
  return (
    <nav
      style={{
        display: "flex",
        justifyContent: "space-between",
        ...style,
      }}
    >
      <button
        disabled={postIndex < 0 || postIndex >= publishedBlogs.length - 1}
        onClick={() => {
          history.push(`/blog/${getPostHash(publishedBlogs[postIndex + 1])}`);
        }}
      >
        Older post
      </button>
      <button onClick={() => history.push("/blog")}>All posts</button>
      {
        <button
          disabled={postIndex <= 0}
          onClick={() => {
            history.push(`/blog/${getPostHash(publishedBlogs[postIndex - 1])}`);
          }}
        >
          Newer post
        </button>
      }
    </nav>
  );
}
