import React, { Fragment, useMemo } from "react";
import { FontSelector } from "../FontSelector";

import { getPostHash, publishedBlogs } from "./blogs";
import { images } from "./images";
import { WebPushSwitch } from "./webpushr";
import { Map } from "./map";
import { getChunkKey } from "./getChunkKey";
import { imageRow } from "./imageRow";
import { useResizeDetector } from "react-resize-detector";
import { Chunk } from "./Chunk";

import "./BlogPage.css";
import { SinglePostNav } from "./SinglePostNav";

const MAX_WIDTH = 1249;
const PADDING = 20;

export function BlogPage({
  font,
  setFont,
  postId,
}: {
  font: string | undefined;
  setFont: (font: string | undefined) => void;
  postId: string | undefined;
}) {
  const containerSize = useResizeDetector<HTMLDivElement>({
    handleHeight: false,
  });
  const postIndex = useMemo(
    () => publishedBlogs.findIndex((blog) => getPostHash(blog) === postId),
    [postId]
  );

  return (
    <div style={{ overflow: "scroll", height: "100%" }}>
      <div
        ref={containerSize.ref}
        style={{
          fontFamily: font,
          paddingLeft: PADDING,
          paddingRight: PADDING,
          maxWidth: MAX_WIDTH,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            display: "flex",
            alignItems: "center",
          }}
        >
          <FontSelector font={font} onChange={setFont} />
          <div style={{ display: "inline-block", paddingLeft: 10 }}>
            🔔 <WebPushSwitch />
          </div>
        </div>

        <h1>Blog</h1>
        <Map />
        {postId && (
          <SinglePostNav postIndex={postIndex} style={{ paddingTop: 20 }} />
        )}
        {postId && postIndex === -1 && (
          <div style={{ textAlign: "center", padding: 30 }}>Not found</div>
        )}
        {publishedBlogs.map((blog, i) => {
          const { title, createdDate, updatedDate, eventDate, chunks } = blog;
          const id = getPostHash(blog);
          if (postId && id !== postId) return null;
          return (
            <Fragment
              key={`${title}${createdDate}${
                typeof eventDate === "symbol" ? "intro" : eventDate
              }${updatedDate}`}
            >
              {i > 0 && !postId && <hr style={{ marginTop: 30 }} />}
              <section
                id={id}
                style={{
                  paddingBottom:
                    i === publishedBlogs.length - 1 || postId ? 30 : 0,
                }}
              >
                <a
                  href={`/blog/${id}`}
                  style={{ color: "currentcolor", textDecoration: "none" }}
                >
                  <h2>{title}</h2>
                </a>
                {Array.isArray(eventDate)
                  ? `${eventDate[0]} to ${eventDate[1]}`
                  : typeof eventDate === "symbol"
                  ? null
                  : eventDate}
                {chunks.map((chunkOrChunks) => {
                  if (Array.isArray(chunkOrChunks)) {
                    const imageOrVideoChunks = chunkOrChunks.filter(
                      (chunk) => "media" in chunk
                    );
                    const nonImageCount =
                      chunkOrChunks.length - imageOrVideoChunks.length;
                    const gap = 1000;
                    const width = 100 * gap;
                    const totalGapWidth = chunkOrChunks.length - 1 * gap;
                    const widthWithoutGap = width - totalGapWidth;
                    const rowWidths =
                      imageOrVideoChunks.length > 0
                        ? imageRow(
                            imageOrVideoChunks.map((chunk) => {
                              if ("media" in chunk) {
                                const imageInfo = images[chunk.media];
                                return imageInfo;
                              } else {
                                throw new Error("not possible");
                              }
                            }),
                            {
                              containerWidth:
                                width -
                                nonImageCount *
                                  (widthWithoutGap / chunkOrChunks.length +
                                    gap),
                              spacing: gap,
                            }
                          )
                        : null;

                    return (
                      <div
                        key={chunkOrChunks.map(getChunkKey).join()}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1%",
                        }}
                        className="row"
                      >
                        {chunkOrChunks.map((chunk, i) => {
                          if ("lat" in chunk) {
                            return null;
                          }
                          const imageIndex = imageOrVideoChunks.indexOf(chunk);
                          return (
                            <Chunk
                              containerWidth={
                                containerSize.width ?? MAX_WIDTH - PADDING * 2
                              }
                              key={getChunkKey(chunk)}
                              chunk={{
                                ...chunk,
                                overrideWidth:
                                  ("overrideWidth" in chunk
                                    ? chunk.overrideWidth
                                    : undefined) ??
                                  (rowWidths && imageIndex >= 0
                                    ? rowWidths.width[imageIndex] / gap
                                    : undefined),
                              }}
                              rowCount={chunkOrChunks.length}
                            />
                          );
                        })}
                      </div>
                    );
                  } else if ("lat" in chunkOrChunks) {
                    return null;
                  } else {
                    return (
                      <Chunk
                        key={getChunkKey(chunkOrChunks)}
                        containerWidth={
                          containerSize.width ?? MAX_WIDTH - PADDING * 2
                        }
                        chunk={chunkOrChunks}
                        rowCount={1}
                      />
                    );
                  }
                })}
              </section>
            </Fragment>
          );
        })}
        {postId && (
          <SinglePostNav postIndex={postIndex} style={{ paddingBottom: 20 }} />
        )}
      </div>
    </div>
  );
}
