import React, { useEffect, useMemo, useRef, useState } from "react";
import { ChunkItem, LocationChunk } from "./blogs";
import { images } from "./images";

export function Chunk({
  chunk,
  rowCount,
  containerWidth,
}: {
  chunk: Exclude<ChunkItem, LocationChunk>;
  rowCount: number;
  containerWidth: number;
}) {
  const [preloadVideo, setPreloadVideo] = useState(false);
  const videoRef = useRef<Element | null>(null);
  const intersectionObserver = useMemo(() => {
    if ("media" in chunk && images[chunk.media].isVideo) {
      return new IntersectionObserver((entries) => {
        if (entries.some((e) => e.isIntersecting)) {
          setPreloadVideo(true);
        }
      });
    }
  }, [chunk]);
  useEffect(() => {
    return () => intersectionObserver?.disconnect();
  }, [intersectionObserver]);

  const [showOverlay, setShowOverlay] = useState(false);
  if ("media" in chunk) {
    const imageWidthPercent = chunk.overrideWidth
      ? chunk.overrideWidth
      : 100 / rowCount;
    const imageData = images[chunk.media];
    const aspectRatio = imageData.height / imageData.width;
    const imageMinHeightPixels =
      Math.min(containerWidth * (imageWidthPercent / 100), 840) * aspectRatio;

    if (imageData.isVideo) {
      if ("youtubeId" in imageData) {
        return (
          <div
            style={{
              display: "flex",
              maxWidth: 840,
              maxHeight: 840,
              minHeight: imageMinHeightPixels,
              paddingTop: "1vw",
              aspectRatio: `${imageData.width} / ${imageData.height}`,
              width: `${
                chunk.overrideWidth ? chunk.overrideWidth : 100 / rowCount
              }%`,
              ...chunk.extraStyles,
            }}
          >
            <iframe
              src={`https://www.youtube.com/embed/${imageData.youtubeId}`}
              title={imageData.youtubeId}
              frameBorder="0"
              allowFullScreen
              style={{
                width: "100%",
                aspectRatio: `${imageData.width} / ${imageData.height}`,
              }}
            ></iframe>
          </div>
        );
      } else {
        return (
          <video
            style={{
              maxWidth: 840,
              maxHeight: 840,
              minHeight: imageMinHeightPixels,
              paddingTop: "1vw",
              width: `${
                chunk.overrideWidth ? chunk.overrideWidth : 100 / rowCount
              }%`,
              ...chunk.extraStyles,
            }}
            src={imageData.src}
            controls={true}
            className="chunk"
            preload={preloadVideo ? "metadata" : "none"}
            ref={(node) => {
              if (videoRef.current) {
                intersectionObserver?.unobserve(videoRef.current);
              }
              videoRef.current = node;
              if (node) {
                intersectionObserver?.observe(node);
              }
            }}
          />
        );
      }
    } else {
      const imageWidthPercent = chunk.overrideWidth
        ? chunk.overrideWidth
        : 100 / rowCount;

      const aspectRatio = imageData.height / imageData.width;
      const imageMinHeightPixels =
        Math.min(containerWidth * (imageWidthPercent / 100), 840) * aspectRatio;
      return (
        <div
          style={{
            maxWidth: 900,
            marginTop: "1vw",
            width: `${imageWidthPercent}%`,
            background: "lightgrey",
            position: "relative",
            ...chunk.extraStyles,
          }}
          className="chunk"
          onMouseEnter={() => setShowOverlay(true)}
          onMouseLeave={() => setShowOverlay(false)}
        >
          <img
            loading="lazy"
            src={imageData.src ?? ""}
            alt={chunk.alt}
            style={{
              display: "block",
              minHeight: imageMinHeightPixels,
              objectFit: "contain",
              maxHeight: 840,
              width: "100%",
            }}
          />
          {chunk.overlay && showOverlay ? (
            <img
              loading="lazy"
              src={images[chunk.overlay].src ?? ""}
              alt={""}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: "100%",
                height: "100%",
              }}
            />
          ) : null}
        </div>
      );
    }
  } else if ("text" in chunk) {
    return (
      <div
        style={{
          width: rowCount > 1 ? 1 : "100%",
          flexGrow: 1,
          flexShrink: 1,
        }}
      >
        <p className="chunk" style={{ maxWidth: 840 }}>
          {chunk.text
            .split("<br/>")
            .flatMap((text, i) => (i > 0 ? [<br key={i} />, text] : text))}
        </p>
      </div>
    );
  } else {
    if (process.env.NODE_ENV !== "production") {
      throw new Error("not handling chunk type");
    }
    return null;
  }
}
