import React, { useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { isReactSnap } from "./isReactSnap";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { getPostHash, publishedBlogs, torontoStart } from "./blogs";
import { getChunkKey } from "./getChunkKey";
import history from "history/browser";
import "./map.css";
import { MarkerWithDelay } from "./MarkerWithDelay";
import { getBoundsZoomLevel } from "./getBoundsZoomLevel";
// import { GarminRoute } from "./GarminRoute";

const markerHeight = 25;

const SHIFT_DOWN =
  publishedBlogs.find((b) => typeof b.shiftMapDown === "number")
    ?.shiftMapDown ?? -22; // started at -22

const DEFAULT_MAP_HEIGHT = 300;
const useGoogleScriptLoader = isReactSnap
  ? () => ({ isLoaded: false })
  : useJsApiLoader;

let seenPins: { lat: number; lng: number }[] = [];
try {
  seenPins = JSON.parse(window.localStorage.getItem("seen-pins") || "[]");
  if (!Array.isArray(seenPins)) {
    seenPins = [];
  }
} catch {}

export function Map() {
  const [projectionHasChanged, setProjectionHasChanged] = useState(false);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const containerSize = useResizeDetector<HTMLDivElement>();
  const mapSize = useMemo(
    () => ({
      width: containerSize.width || 400,
      height: containerSize.height || DEFAULT_MAP_HEIGHT,
    }),
    [containerSize.width, containerSize.height]
  );
  const { isLoaded } = useGoogleScriptLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAOhma7nqIMnMW14MgXhPorcHZ9JRK_n5U",
  });
  const { mapCenter: mapCenterRaw, zoom } = useMemo(() => {
    if (!isLoaded || !google) return { mapCenter: torontoStart, zoom: 10 };
    const bounds = new google.maps.LatLngBounds(torontoStart);
    publishedBlogs.forEach((blog) => {
      blog.chunks.forEach((chunks) => {
        (Array.isArray(chunks) ? chunks : [chunks]).forEach((chunk) => {
          if ("lat" in chunk) {
            bounds.extend(chunk);
          }
        });
      });
    });
    return {
      mapCenter: bounds.getCenter(),
      zoom: getBoundsZoomLevel(bounds, mapSize),
    };
  }, [isLoaded, mapSize]);

  const mapCenter = useMemo(() => {
    const offsetX = 0;
    const offsetY = SHIFT_DOWN;
    const scale = Math.pow(2, zoom);

    const worldCoordinateCenter = map
      ?.getProjection()
      ?.fromLatLngToPoint(mapCenterRaw);
    if (!worldCoordinateCenter || !isLoaded || !projectionHasChanged) {
      return mapCenterRaw;
    }
    const pixelOffset = new google.maps.Point(offsetX / scale, offsetY / scale);

    return (
      map
        ?.getProjection()
        ?.fromPointToLatLng(
          new google.maps.Point(
            worldCoordinateCenter.x - pixelOffset.x,
            worldCoordinateCenter.y + pixelOffset.y
          )
        ) ?? mapCenterRaw
    );
  }, [mapCenterRaw, map, zoom, isLoaded, projectionHasChanged]);
  const markerIcon = useMemo((): google.maps.Icon | undefined => {
    if (isLoaded) {
      return {
        url: "/pin.png",
        scaledSize: new google.maps.Size(
          markerHeight * 0.5708333333333333,
          markerHeight
        ),
      };
    }
  }, [isLoaded]);
  const currentIcon = useMemo((): google.maps.Icon | undefined => {
    if (isLoaded) {
      return {
        url: "/pin-star.png",
        scaledSize: new google.maps.Size(
          markerHeight * 0.5708333333333333,
          markerHeight
        ),
      };
    }
  }, [isLoaded]);
  const [seenLocationChunks, unseenLocationChunks] = useMemo(() => {
    return publishedBlogs
      .flatMap((blog, blogIndex) => {
        const id = getPostHash(blog);
        return blog.chunks
          .flatMap((chunk, entryIndex) =>
            (Array.isArray(chunk) ? chunk : [chunk])
              .filter(
                (chunk): chunk is Extract<typeof chunk, { lat: number }> =>
                  "lat" in chunk
              )
              .map((chunk) => ({
                key: `${blogIndex}${entryIndex}${getChunkKey(chunk)}`,
                blogId: id,
                ...chunk,
              }))
          )
          .reverse();
      })
      .reverse()
      .reduce(
        (acc: [Array<typeof chunk>, Array<typeof chunk>], chunk) => {
          const isSeen = !!seenPins.find(
            (pin) => chunk.lat === pin.lat && chunk.lng === pin.lng
          );
          if (isSeen) {
            acc[0].push(chunk);
          } else {
            acc[1].push(chunk);
          }
          return acc;
        },
        [[], []]
      );
  }, []);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.localStorage.setItem(
        "seen-pins",
        JSON.stringify([...seenLocationChunks, ...unseenLocationChunks])
      );
    }, unseenLocationChunks.length * 150);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [seenLocationChunks, unseenLocationChunks]);

  return (
    <div
      ref={containerSize.ref}
      className="map-container"
      style={{
        position: "relative",
        maxHeight: DEFAULT_MAP_HEIGHT,
        width: "100%",
      }}
    >
      <div style={{ position: "absolute", top: 0, left: 0 }}>
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={mapSize}
            center={mapCenter}
            zoom={zoom}
            options={{ disableDefaultUI: true, isFractionalZoomEnabled: true }}
            onLoad={setMap}
            onProjectionChanged={() => setProjectionHasChanged(true)}
          >
            {/* <GarminRoute /> */}
            {seenLocationChunks.map((chunk, i, arr) => {
              return (
                <Marker
                  key={chunk.key}
                  position={chunk}
                  // zIndex={
                  //   arr.length - 1 === i && !unseenLocationChunks.length
                  //     ? 999
                  //     : undefined
                  // }
                  onClick={() => {
                    if (chunk.blogId) {
                      history.push(`/blog/${chunk.blogId}`);
                    }
                  }}
                  icon={
                    arr.length - 1 === i && !unseenLocationChunks.length
                      ? currentIcon
                      : markerIcon
                  }
                />
              );
            })}
            {unseenLocationChunks.map((chunk, i, arr) => {
              return (
                <MarkerWithDelay
                  key={chunk.key}
                  index={i}
                  position={chunk}
                  animation={google.maps.Animation.DROP}
                  markerIcon={arr.length - 1 === i ? currentIcon : markerIcon}
                />
              );
            })}
          </GoogleMap>
        )}
      </div>
    </div>
  );
}
